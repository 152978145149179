<template>
  <div class="ellipse-bg-bottom" >

    <!-- <div class="ellipse-bg-bottom"></div> -->
    <div class="ellipse-bg">
    <div class="d-flex mx-8 pt-6 flex-column">
      <div>
        <icon id="page-ipredix-colored" :size="'30px'" class="mt-n3 mr-2"></icon>
        <!-- <v-icon large color="primary" class="mb-4 mr-2">mdi-magnify-expand</v-icon> -->
        <span class="text-h1">PrediX Platform</span>
        
      </div>
      <div class="mx-1">
        <v-row class="mt-1 mb-1 mx-0">
          <v-col class="ml-0 pl-0">
            <c-select class="text-h4" :items="normalDatasets" v-model="selectedNormalDatabaseId" icon="designspace-database" item-text="name" item-value="id" label="Database" persistent-hint @change="onNormalDatasetChange()"></c-select>
          </v-col>
          <v-col class="ml-2 mr-0 px-0">
            <c-select
              class="text-h4"
              :items="standardDatasets"
              v-model="selectedStandardDatabaseId"
              icon="standard-database"
              prepend-inner-icon="mdi-ruler-square-compass"
              item-text="name"
              item-value="id"
              label="Standard Database"
              persistent-hint
              @change="onStandardDatasetChange()"
            ></c-select>
          </v-col>
        </v-row>
        <div class="filterBox d-flex flex-column justify-center align-center">
          <div v-if="!isDatabaseSelected" class="emptyState body-2 font-weight-bold my-16">
            <img class="ml-13" src="@/assets/img/predix-casting-emptystate.png" />
            <p>Select Database and Benchmark Alloy to see the result</p>
          </div>
          <div v-else class="castingBox mb-auto">
            <div class="tabHeaderBox">
              <v-tabs v-model="selectedDatabaseTab" height="40px">
                <v-tab class="text-h4"> <icon color="primary" size="21px" id="predix-normal-db" class="mr-1"></icon> {{ selectedNormalDatabaseName }} </v-tab>
                <v-tab class="text-h4"> <icon color="primary" size="21px" id="predix-standard-db" class="mr-1"></icon> {{ selectedStandardDatabaseName }} </v-tab>
              </v-tabs>
            </div>
            <div>
              <AlloyPropertySelector
                :package_id="PACKAGE_ID"
                :standardAlloys="standardAlloys"
                :databaseId="selectedDatabaseTab == 0 ? selectedNormalDatabaseId : selectedStandardDatabaseId"
                :databaseType="selectedDatabaseTab == 0 ? 1 : 2"
                :fieldGroups="fieldGroups"
                v-on:onExplore="onExplore"
                v-on:onExploreStart="onExploreStart"
              ></AlloyPropertySelector>
            </div>
          </div>
        </div>
        <div  v-if="selectedNormalDatabaseId > -1 && selectedStandardDatabaseId > -1 && isExplored == true">
          <div v-if=" exploreResult.length > 0" class="d-flex flex-row mb-6">
            <div class="resultBoxSidebar">
                <div class="resultTab" :class="resultTabActive == 0 ? 'resultTabActive' : ''" @click="resultTabActive=0"><icon id="magnify"></icon> Explore Properties</div>
                <div class="resultTab" :class="resultTabActive == 1 ? 'resultTabActive' : ''" @click="resultTabActive=1"><icon id="alloy-comparison"></icon> Alloy Comparison</div>
            </div>
            <div class="resultBoxMain">
                <div v-if="resultTabActive == 0">
                  <v-expansion-panels class="px-3 pb-3"  flat focusable >
                    <v-expansion-panel class="mt-4"  style="background-color: transparent !important;"  v-for="castingPropertyGroup in exploreResultCastingPropertiesGroups" :key="castingPropertyGroup.id">
                      <v-expansion-panel-header class="text-subtitle-2 expansionPanelHeader " >
                        <div><v-icon>mdi-chevron-right</v-icon>{{ castingPropertyGroup.name }}</div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content style="background-color: transparent !important;">
                        <CastingExploreResult
                          class="pt-5"
                          :exploreResult="grouppedExploreResult[castingPropertyGroup.id]"
                          :castingPropertiesGroupId="castingPropertyGroup.id"
                          :castingPropertiesGroupName="castingPropertyGroup.name"
                          :standardAlloys="standardAlloys"
                          :standardDatasetName="standardDatasets.find((item) => item.id === selectedStandardDatabaseId).name"
                          :standardDatasetId="selectedStandardDatabaseId"
                          :package_id="PACKAGE_ID"
                        ></CastingExploreResult>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>                
                </div>
                <div v-else>
                  <CastingAlloyComparison
                    :selectedNormalDatabaseId="selectedNormalDatabaseId"
                    :selectedStandardDatabaseId="selectedStandardDatabaseId"
                    :normalDatabasePropertyData="exploreResult"
                    :standardAlloys="standardAlloys"
                    :normalDatasetFields="normalDatasetFields"
                    :fieldGroups="fieldGroups"
                    :selectedHeatTreatment="selectedHeatTreatment"
                    :selectedCastingGroups="selectedCastingGroups"
                    :package_id="PACKAGE_ID"
                  >
                  </CastingAlloyComparison>

                </div>
            </div>
          </div>
          <div v-else class="pa-4">No result has found! change the parameters!</div>  
        </div>
      </div>
    </div>

    </div>
  </div>
</template>
<script>
import { getFieldGroups } from "@/api/settings";
import { getDatasets, getData } from "@/api/user/dataset";
import AlloyPropertySelector from "@/components/Predix/AlloyPropertySelector";
import CastingExploreResult from '@/components/Predix/CastingExploreResult'
import CastingAlloyComparison from '@/components/Predix/CastingAlloyComparison'
import CSelect from "@/components/Vuetify/CSelect.vue";

export default {
  name: "PredixCasting",
  components: { CSelect, AlloyPropertySelector,CastingExploreResult, CastingAlloyComparison},
  data: () => ({
    selectedDatabaseTab: 0,
    PACKAGE_ID: 9,
    CASTING_GROUP: "CASTING",
    normalDatasets: [],
    standardDatasets: [],
    selectedNormalDatabaseId: null,
    selectedStandardDatabaseId: null,
    isExplored: false,

    resultTabActive:0,
    fieldGroups: [],
    standardAlloys: [],
    selectedStandardAlloy: 0,
    compositionType: "COMPOSITION_AT",
    standardAlloyProperties: [],
  }),
  created() {
    this.loadFieldGroups();
    this.loadDatasets();
  },
  computed: {
    selectedNormalDatabaseName() {
      return this.normalDatasets.find((item) => item.id === this.selectedNormalDatabaseId)?.name || "";
    },
    selectedStandardDatabaseName() {
      return this.standardDatasets.find((item) => item.id === this.selectedStandardDatabaseId)?.name || "";
    },
    isDatabaseSelected() {
      return this.selectedNormalDatabaseId && this.selectedStandardDatabaseId;
    },
  },
  methods: {
    loadFieldGroups() {
      const _this = this;
      getFieldGroups(function (res) {
        _this.fieldGroups = res;
      });
    },
    onNormalDatasetChange() {
      this.isExplored = false;
    },
    onStandardDatasetChange() {
      this.isExplored = false;
      this.loadAlloyNames();
    },
    loadAlloyNames() {
      const _this = this;
      const alloyNameGroupId = this.fieldGroups.find((item) => item.groupType === "ID").id;
      getData(this.PACKAGE_ID, this.selectedStandardDatabaseId, [], [alloyNameGroupId], function (res) {
        _this.standardAlloys = res.result.map((item) => {
          var name = Object.keys(item)[0];
          return { id: item[name], name: item[name] };
        });
        if (_this.standardAlloys.length > 0) {
          _this.selectedStandardAlloy = _this.standardAlloys[0].id;
          _this.loadStandardAlloyProps();
        }
      });
    },
    loadStandardAlloyProps() {
      const _this = this;
      const alloyNameGroupId = this.fieldGroups.find((item) => item.groupType === this.compositionType).id;
      getData(this.PACKAGE_ID, this.selectedStandardDatabaseId, [{ f0: this.selectedStandardAlloy }], [alloyNameGroupId], function (res) {
        const alloy = res.result[0];
        _this.standardAlloyProperties = [];
        for (var key of Object.keys(alloy).sort()) {
          _this.standardAlloyProperties.push({ id: key, value: alloy[key] });
        }
      });
    },
    loadDatasets() {
      const _this = this;
      getDatasets(this.PACKAGE_ID, function (res) {
        _this.normalDatasets = res.datasets.filter((item) => item.datasetType.id === 1);
        _this.standardDatasets = res.datasets.filter((item) => item.datasetType.id === 2);
      });
    },
    onExploreStart() {
      this.isExplored = false;
    },
    onExplore(exploreResult, databaseFields, selectedHeatTreatment, selectedCastingGroups, exploreDbType) {
      this.exploreResult = exploreResult;
      this.grouppedExploreResult = {};
      this.normalDatasetFields = databaseFields;

      let castingPropertiesGroups = new Set();
      this.normalDatasetFields.forEach((field) => field.groups.filter((grp) => grp.groupType === "CASTING_PROPERTIES").forEach((g) => castingPropertiesGroups.add(g.id)));
      for (const groupId of castingPropertiesGroups) {
        this.grouppedExploreResult[groupId] = exploreResult.filter((r) => r.groups.includes(groupId));
      }
      this.exploreResultCastingPropertiesGroups = this.fieldGroups.filter((item) => castingPropertiesGroups.has(item.id));
      this.selectedCastingGroups = selectedCastingGroups;
      this.selectedHeatTreatment = selectedHeatTreatment;
      this.exploreDbType = exploreDbType;
      this.isExplored = true;
    },
  },
};
</script>

<style scoped>
.v-tab--active {
  color: black !important;
}
.theme--dark .v-tab--active {
  color: white !important;
}
.ellipse-bg {
  background-image: radial-gradient(farthest-corner at 100% 0%, rgba(3, 147, 168,0.40) 0%, transparent 45% );
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position-x: right;
  background-position-y: center;
  background-size: 100vw 100vh;
  min-height: 100vh;
}

.ellipse-bg-bottom {
  background-image: radial-gradient(farthest-corner at 0% 100%, rgba(3, 147, 168,0.40) 0%, transparent 45% );
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position-x: left;
  background-position-y: center;
  background-size: 100vw 100vh;
  min-height: 100vh;
}

.tabHeaderBox {
  box-shadow: 0px 7px 64px 10px #00000012;
  border-radius: 6px;
  background-color: white;
  padding: 12px;
  /* position: relative; */
  width: 100%;
}

.theme--dark .tabHeaderBox{
  background-color: #1e1e1e;

}

.expansionPanelHeader{
  background-color: white;
}

.theme--dark .expansionPanelHeader{
  background-color: #18191A;
}

.theme--dark .expansionPanelHeader.v-expansion-panel-header--active{
  background-color: black;
}


.filterBox {
  border: 1px solid var(--v-primary-base);
  border-radius: 16px;
  padding: 40px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.70);
  margin-bottom: 30px;

}

.theme--dark .filterBox{
  background-color: rgba(27,33,36);

}

.resultBoxSidebar {
  border-radius: 16px 0px 0px 16px;
  width: 280px;
  padding: 25px;
  background-color: rgba(255, 255, 255, 0.70);
  box-shadow: 0px 7px 64px 0px rgba(0, 0, 0, 0.07);
}
.theme--dark .resultBoxSidebar {
  /* background-color: rgba(26, 27, 29,0.8); */
  background: linear-gradient(rgba(26, 27, 29), rgba(26, 27, 29,0.25))
  

}

.resultBoxMain {
  border: 1px solid var(--v-primary-base);
  border-radius: 0px 16px 16px 0px;
  width: 100%;
  background: linear-gradient(90deg, #a0cce916 90% , transparent);
}

.resultTab{
  padding: 12px;
  cursor: pointer;
}
.resultTabActive{
  background-color:white;
  border-radius: 4px;
  border-left: 4px solid var(--v-primary-base);
  font-weight: 700;
}
.theme--dark .resultTabActive{
  background-color:#18191A;
}


/* 
.resultTabActive{
  #EBEAFA
} */

.v-expansion-panel-header{
  border-radius: 6px !important;
  min-height: 48px !important;
  height: 48px !important;

}

.v-expansion-panel-content{
  /* border-radius: 6px 6px 0px 0px !important; */
  border-width: 0px 1px 1px 1px !important;
  border-color: #0393A850 !important;
  border-style: solid !important;

}
.v-expansion-panel-header--active{
  opacity: 0.9 !important;
  border-radius: 6px 6px 0px 0px !important;
  border-width: 1px 1px 0px 1px !important;
  border-color: #0393A890 !important;
  border-style: solid !important;
}
.theme--dark .v-expansion-panel-header--active{
  opacity: 0.9 !important;
  background-color: rgb(27, 27, 27);
  border-radius: 6px 6px 0px 0px !important;
  border-width: 1px 1px 0px 1px !important;
  border-color: #0393A890 !important;
  border-style: solid !important;
}
.v-expansion-panel--active > .v-expansion-panel-header {
    min-height: 48px !important;
}



.castingBox {
  width: 100%;
  height: 100%;
}
.emptyState {
  width: 250px;
  text-align: center;
}
</style>
